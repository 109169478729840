import { useDispatch, useSelector } from "react-redux";
import { getSelectedDate, reset, selectDate } from "../../../store/dateSelectionSlice";
import { useEffect } from "react";
import { ButtonStyle, HeaderContainer, TodayButtonStyle } from "./Styles";
import { getDates } from "../../../store/appointmentsSlice";
import { FormattedMessage } from "react-intl";

const WeekHeader = () => {
    const selectedDay = useSelector(getSelectedDate);
    const days = useSelector(getDates);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(selectDate(days[0]));
        };
    }, [dispatch])

    const selectDay = (day: string) => {
        dispatch(selectDate(day));
    }

    return (
        <>
            <HeaderContainer>
                <TodayButtonStyle clicked={!selectedDay || days[0]===selectedDay} onClick={() => selectDay(days[0])}><FormattedMessage id="today" /></TodayButtonStyle>
                {
                    days.slice(1).map((day) => {
                        return <ButtonStyle clicked={day===selectedDay} onClick={() => selectDay(day)}>{day}</ButtonStyle>;
                    })
                }
            </HeaderContainer>
        </>
    )
}

export default WeekHeader;