import { useState } from "react"
import barberLogo from "../../assets/images/salon_yousef_logo.svg"
import {
  MenuButtonContainer,
  MenuButtonStyle,
  StyleHeaderContainer,
  StyleHeaderLogo,
  StyleLogo
} from "./Header.styles"
import Menu from "../../menuPage/menuPage"
import LanguagesController from "../../langaugesController/LanguagesController"

interface HeaderProps {
  onOpen:()=>void
}

const Header:React.FC<HeaderProps> = ({ onOpen}) => (
    <MenuButtonContainer>
      <MenuButtonStyle onClick={onOpen} />
      <LanguagesController />
    </MenuButtonContainer>
)

export default Header
