import styled from "styled-components";

export const BookingBoxContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 90%;
    height: 500px;
    z-index: 3;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
`

export const BookingBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #FF4F2D;
    height: 100%;
    font-family: 'Jost',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 39px;
    color: #222532;
    border-radius: 10px;
`

export const BoxTitleStyle = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px 0;
`

export const TimesListContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    height: 70px;
    padding-left: 5px;
    overflow-x: auto;
    
    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    /* Prevent text selection */
    user-select: none;
`

export const TimeContainer = styled.div`
    padding: 5px 10px;
    background-color: #222532;
    color: #FFFFFF;
    border-radius: 5px;
`

export const InputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    gap: 20px;
`

export const InputContainer = styled.div`
    width: 100%;
`

export const InputTitleStyle = styled.div`
    
`

export const InputValueStyle = styled.input`
    width: 98%;
    height: 40px;
    border-radius: 5px;
    border: 0.5px solid black;
`

export const SubmitButtonStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4CAF50;
    height: 50px;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 30px;
`