import { useEffect, useState } from "react"
import Button from "../../components/goldButton/Button"
import MovingLogo from "../../components/movingLogo/MovingLogo"
import {
  BookNowContainer,
  ButtonsContainerStyle,
  DownScreenStyle,
  MenuButtonContainer,
  MenuButtonStyle,
  MenuContainer,
  TopScreenStyle
} from "./Styles"

import BookNowPanel from "../bookNowPanel/BookNowPanel"
import SignInPopup from "../signInPopup/SignInPopup"

import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import Menu from "../../components/menuPage/menuPage"
import LanguagesController from "../../components/langaugesController/LanguagesController"
import { isLoggedIn } from "../../store/authSlice"

const BookNow = () => {
  const { isLogged, verified, blocked } = useSelector(
    (state: RootState) => state.auth
  )
  const isLoggedInStatus = useSelector(isLoggedIn)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
  const [isDisplayed, setIsDisplayed] = useState<boolean>(true)

  const navigate = useNavigate()

  useEffect(() => {
    if (isLoggedInStatus) {
      setIsPopupOpen(false)
    }
  }, [isLoggedInStatus])

  const openBookNow = () => {
    if (!isLoggedInStatus) {
      setIsPopupOpen(true)
    } else {
      setIsOpen(!isOpen)
    }
  }

  const closePopup = () => {
    setIsPopupOpen(false)
  }

  const openMenu = () => {
    setIsDisplayed(false)
  }

  const closeMenu = () => {
    setIsDisplayed(true)
  }

  const navigateToHome = () => {
    navigate("/home")
  }

  const renderBookingPanel = () => {
    if (isLogged) {
      if (blocked) {
        return <>Blocked</>
      }
      if (isLoggedInStatus) {
        return <BookNowPanel closeFunc={openBookNow} isOpen={isOpen} isEdit={false}/>
      }
    }
  }

  return (
    <>
      {renderBookingPanel()}
      {isPopupOpen && <SignInPopup closeWindow={closePopup} />}
      
      <MenuContainer>
        <Menu onClose={closeMenu} />
      </MenuContainer>
      <BookNowContainer isDisplayed={isDisplayed}>
        <MenuButtonContainer>
          <LanguagesController />
          <MenuButtonStyle onClick={openMenu} />
        </MenuButtonContainer>
        <TopScreenStyle>
          <MovingLogo isTop={!isOpen} />
        </TopScreenStyle>
        <DownScreenStyle>
          <ButtonsContainerStyle>
            <Button
              backgroundColor="Gold"
              text="book_now"
              onClick={openBookNow}
            />
            <Button
              backgroundColor="Gray"
              text="visit_home"
              onClick={navigateToHome}
            />
          </ButtonsContainerStyle>
        </DownScreenStyle>
      </BookNowContainer>
    </>
  )
}

export default BookNow
