import { FormattedMessage, useIntl } from "react-intl";
import { BoxBodyContainer, BoxContainer, BoxTitleStyle, HeaderStyle, TDDeleteStyle, TDStyle, THStyle, THDeleteStyle, TRStyle, TableBodyStyle, TableHeaderStyle, TableStyle, AddShiftContainer, ValueStyle, KeyStyle, ChoiceBoxStyle, SubmitBtnStyle, ChangeStatusBtnStyle, DeleteButtonStyle, InputTextStyle, InputTimeStyle, LocalizationProviderStyle, InputContainerStyle } from "./Style";
import { useEffect, useState } from "react";
import CancelIcon from "../assets/icons/XIcon.svg";
import { useDispatch } from "react-redux";
import { reset, setErrorMessage } from "../../store/messagesSlice";
import { createShift, deleteShift, getShifts } from "../../services/BackendServices";
import ShiftType from "../../types/rest/ShiftType";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

const ShiftsControllerBox = () => {
    const dispatch = useDispatch();
    const [value, setValue] = useState<Dayjs | null>(dayjs(new Date()));
    const [addingShift, setAddingShift] = useState<boolean>(false);
    const [inProgress, setInProgress] = useState(false);
    const { formatMessage } = useIntl();

    const [shifts, setShifts] = useState<ShiftType[]>([]);

    const [name, setName] = useState("");
    const [from, setFrom] = useState<Date>(new Date());
    const [to, setTo] = useState<Date>(new Date());
    const [mpc, setMPC] = useState("30");

    useEffect(() => {
        dispatch(reset());
        fetchData();
    }, [])

    const fetchData = async () => {
        const shifts = await getShifts();
        if (Array.isArray(shifts)) {
            setShifts(shifts);
        }
        else {
            setShifts([]);
        }
    }

    const switchBox = () => {
        if (inProgress) return;
        setAddingShift(!addingShift);
    }

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const handleFromChange = (newValue: any) => {
        setFrom(newValue.$d);
    };

    const handleToChange = (newValue: any) => {
        setTo(newValue.$d);
    };

    function formatDate(date: Date) {
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    const handleMPCChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMPC(e.target.value);
    };

    const addNewShift = async () => {
        if (inProgress) return;
        if(name===""||mpc==="") {
            dispatch(setErrorMessage("Need to add all fields."));
            return;
        }
        setInProgress(true);
        const shift = await createShift(name, formatDate(from), formatDate(to), mpc);

        if ("message" in shift) {
            dispatch(setErrorMessage(`${shift.message}`));
        }

        setInProgress(false);
        await fetchData();
        switchBox();
    }

    const deleteShiftFun = async (shiftId: string) => {
        if (inProgress) return;
        setInProgress(true);
        const shiftRest = await deleteShift(shiftId);
        if (shiftRest !== "deleted" && "message" in shiftRest) {
            dispatch(setErrorMessage(`${shiftRest.message}`));
        }

        setInProgress(false);
        fetchData();
    }

    const getShiftsRows = () => {
        return (
            <TableBodyStyle>
                {shifts.map(shift => {
                    return <TRStyle>
                        <TDStyle>{shift.shiftName}</TDStyle>
                        <TDStyle>{shift.fromHour}</TDStyle>
                        <TDStyle>{shift.toHour}</TDStyle>
                        <TDStyle>{`${shift.mpc}`}</TDStyle>
                        <TDDeleteStyle>
                            <DeleteButtonStyle src={CancelIcon} onClick={() => deleteShiftFun(shift.id)} />
                        </TDDeleteStyle>
                    </TRStyle>
                })}
            </TableBodyStyle>
        )
    }

    return (
        <BoxContainer boxSize={addingShift ? "small" : "big"}>

            <ChangeStatusBtnStyle onClick={switchBox}>Switch to {formatMessage({ id: addingShift ? "shifts" : "add_shift" })}</ChangeStatusBtnStyle>
            <BoxBodyContainer>
                <BoxTitleStyle>
                    <FormattedMessage id={addingShift ? "add_shift" : "shifts"} />
                </BoxTitleStyle>
                {(!addingShift && <TableStyle>
                    <TableHeaderStyle>
                        <TRStyle>
                            <THStyle>
                                <FormattedMessage id="name" />
                            </THStyle>
                            <THStyle>
                                <FormattedMessage id="from" />
                            </THStyle>
                            <THStyle>
                                <FormattedMessage id="to" />
                            </THStyle>
                            <THStyle>
                                <FormattedMessage id="MPC" />
                            </THStyle>
                        </TRStyle>
                    </TableHeaderStyle>
                    {getShiftsRows()}
                </TableStyle>) ||
                    <AddShiftContainer>
                        <ChoiceBoxStyle>
                            <InputTextStyle
                                id="Name"
                                label="Name"
                                type="text"
                                autoComplete="Name"
                                variant="standard"
                                onChange={handleNameChange}
                            />
                        </ChoiceBoxStyle>
                        <ChoiceBoxStyle>
                            <InputTextStyle
                                id="MPC"
                                label="MPC"
                                type="number"
                                defaultValue={mpc}
                                inputMode='numeric'
                                variant="standard"
                                onChange={handleMPCChange}
                            />
                        </ChoiceBoxStyle>
                        <ChoiceBoxStyle>
                            <LocalizationProviderStyle dateAdapter={AdapterDayjs}>
                                <InputContainerStyle>
                                    <InputTimeStyle
                                        label="From Time"
                                        value={value}
                                        onChange={handleFromChange}
                                    />
                                    <InputTimeStyle
                                        label="To Time"
                                        value={value}
                                        onChange={handleToChange}
                                    />
                                </InputContainerStyle>
                            </LocalizationProviderStyle>
                        </ChoiceBoxStyle>
                        <SubmitBtnStyle value="Add Shift" onClick={addNewShift} />
                    </AddShiftContainer>}
            </BoxBodyContainer>
        </BoxContainer>
    )
}

export default ShiftsControllerBox;