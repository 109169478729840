import React, { useState } from "react"
import {
  BackDivBlurred,
  BackPopupWindowStyle,
  BackgroundPopupStyle,
  ButtonsContainerStyle,
  CancelButtonStyle,
  CloseButtonStyle,
  ConfirmButtonStyle,
  ERRORMessageStyle,
  MessageBodyStyle,
  MessageContainerStyle,
  MessageHeaderStyle,
  MessageTextStyle,
  OkLogo,
  PopupWindowStyle,
  SuccessfullyMessageStyle
} from "./Styles"
import IConfirmMessageProps from "./IConfirmMessageProps"
import { FormattedMessage } from "react-intl"
import okLogo from "../assets/icons/OkLogo2.svg"
const ConfirmMessage: React.FC<IConfirmMessageProps> = ({
  headerText,
  bodyText,
  message,
  isPassed,
  isConfirmed,
  confirmFunc,
  cancelFunc
}) => {
  return (
    <BackDivBlurred>
      <BackgroundPopupStyle onClick={cancelFunc} />
      <PopupWindowStyle isVisible={true}>
        <MessageContainerStyle>
          {(isConfirmed && (
            <>
              {(!isPassed && (
                <ERRORMessageStyle>{message}</ERRORMessageStyle>
              )) || (
                <SuccessfullyMessageStyle>{message}</SuccessfullyMessageStyle>
              )}
              <CloseButtonStyle onClick={cancelFunc}>
                <FormattedMessage id="close" />
              </CloseButtonStyle>
            </>
          )) || (
            <>
              <MessageTextStyle>
                <MessageHeaderStyle>{headerText}</MessageHeaderStyle>
                <MessageBodyStyle>{bodyText}</MessageBodyStyle>
              </MessageTextStyle>
              <ButtonsContainerStyle>
                <ConfirmButtonStyle onClick={confirmFunc}>
                  <FormattedMessage id="confirm" />
                </ConfirmButtonStyle>
                <CancelButtonStyle onClick={cancelFunc}>
                  <FormattedMessage id="cancel" />
                </CancelButtonStyle>
              </ButtonsContainerStyle>
            </>
          )}
        </MessageContainerStyle>
      </PopupWindowStyle>
      <BackPopupWindowStyle onClick={cancelFunc} />
    </BackDivBlurred>
  )
}

export default ConfirmMessage
