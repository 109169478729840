import { ControllerContainer, HeaderStyle, MainControllerContainer, RowsStyle, TableStyle } from "./Styles";
import WeekHeader from "../../components/calendar/weekHeader/WeekHeader";
import ClientsTable from "../../components/calendar/clientsTable/ClientsTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useEffect } from "react";
import { setAppointments } from "../../store/appointmentsSlice";
import { getBookingList as fetchBookingList } from "../../services/BackendServices"
import BookingButton from "../../components/bookingButton/BookingButton";

const ControlPage = () => {
    const dispatch = useDispatch();
    const { role, isLogged } = useSelector((state: RootState) => state.auth);

    useEffect(() => {
        if (role === "admin") {
            fetchData();

            const intervalId = setInterval(async () => {
                fetchData();
            }, 10000);

            return () => clearInterval(intervalId);
        }
    }, [role, isLogged]);

    const fetchData = async () => {
        const bookingList = await fetchBookingList();
        if ("appointments" in bookingList) {
            dispatch(setAppointments(bookingList.appointments));
        }
        else {
            dispatch(setAppointments([]));
        }
    }

    return (
        <MainControllerContainer>
            <ControllerContainer>
                <TableStyle>
                    <HeaderStyle>
                        <WeekHeader /> {/* //* this is the days week header on top */ }
                    </HeaderStyle>
                    <RowsStyle>
                        <ClientsTable />
                    </RowsStyle>
                </TableStyle>
            </ControllerContainer>
            <BookingButton />
        </MainControllerContainer>
    )
}

export default ControlPage;