import { FormattedMessage } from "react-intl"
import {
  SliderFooter,
  SliderContainer,
  SliderHeader,
  SliderFooterText,
  StyleHeaderContainer,
  StyleLogo,
} from "./Styles"
import barberLogo from "../../components/assets/images/salon_yousef_logo.svg"

const MainSlider = () => {
  return (
      <SliderContainer>
        <SliderHeader />
        <StyleHeaderContainer>
          <StyleLogo alt="barberLogo" src={barberLogo} />
        </StyleHeaderContainer>

        <SliderFooter>
          <SliderFooterText>
            <FormattedMessage id="barber_shop_title" />
          </SliderFooterText>
        </SliderFooter>
      </SliderContainer>
  )
}

export default MainSlider
