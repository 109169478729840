import styled from "styled-components";
import InstagramIcon from "@mui/icons-material/Instagram"

export const FooterContainerStyle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
    bottom: 0;
    height: 379px;
    /* background: transparent; */
    color: #FFF;
    background: linear-gradient(0deg, #262732 4.75%, rgba(97, 53, 57, 0.96) 54.8%, #8E3939 100%);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Jost;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: 'Jost',sans-serif;
`
export const FooterDetailsStyle = styled.div`

`

export const FooterDetailLineStyle = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
`

export const LocationStyle = styled.div`
    font-size: 28px;
    line-height: 65px;
`

export const IconStyle = styled.img`

`

export const PhoneNumberStyle = styled.div`
    font-size: 28px;
    line-height: 65px;
`

export const SocialMediaContainerStyle = styled.div`
    display: flex;
    justify-content: center;
    gap: 50px;
`

export const FBStyle = styled.img`
    stroke-width: 2.5px;
    stroke: #FFFBFB;
    width: 70.378px;
    height: 62px;
    flex-shrink: 0;
    
`

export const InstagramStyle = styled.img`
    stroke-width: 2.5px;
    stroke: #FFFBFB;
    width: 70.378px;
    height: 62px;
    flex-shrink: 0;
    color: white;
`

export const SignatureStyle = styled.div`
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: 'Jost',sans-serif;
`

export const SocialInstaGramHandler = styled(InstagramIcon)`
    width: 80.378px;
    height: 72px;
    color:#FFFBFB;
    &:first-child{
        width: 80.378px;
        height: 72px;
        color:#FFFBFB;
    }
`;
