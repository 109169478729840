import { ChangeEvent, useEffect, useRef, useState } from "react"
import { HeaderStyle } from "../../containers/controlPage/Styles"
import WeekHeader from "../calendar/weekHeader/WeekHeader"
import {
  BookingBodyContainer,
  BookingBoxContainer,
  BoxTitleStyle,
  InputContainer,
  InputTitleStyle,
  InputValueStyle,
  InputsContainer,
  SubmitButtonStyle,
  TimeContainer,
  TimesListContainer
} from "./Style"
import AvailableTimeFailedType from "../../types/rest/AvailableTimeFailedType"
import AvailableTimeType from "../../types/rest/AvailableTimeType"
import { bookNow, getAvailableTimes } from "../../services/BackendServices"
import { useDispatch, useSelector } from "react-redux"
import { getSelectedDate } from "../../store/dateSelectionSlice"
import { FormattedMessage } from "react-intl"
import ShiftsList from "../shiftsList/ShiftsList"
import { setErrorMessage } from "../../store/messagesSlice"

const BookingBox = () => {
  const dispatch = useDispatch()

  const selectedDay = useSelector(getSelectedDate)
  const [inProgress, setInProgress] = useState(false)

  const [times, setTimes] = useState<string[] | undefined>(undefined)

  const [selectedDate, setSelectedDate] = useState<string | undefined>(
    undefined
  )
  const [selectedTime, setSelectedTime] = useState<string | undefined>(
    undefined
  )
  const fullName = useRef("")
  const phoneNumber = useRef("")

  useEffect(() => {
    if (selectedDay) fetchAvailableTimes(selectedDay)
  }, [selectedDay])

  const fetchAvailableTimes = async (date: string) => {
    const availableTime: AvailableTimeFailedType | AvailableTimeType =
      await getAvailableTimes()
    if (availableTime.statusCode === 200 && "body" in availableTime) {
      const selectedTimes = availableTime.body.availableTime.find(
        (d) => String(d.dateValue) === date
      )
      setSelectedDate(selectedTimes?.date)
      setTimes(selectedTimes?.timeList)
      const firstFreeTime = selectedTimes?.timeList[0]
      setSelectedTime(firstFreeTime)
    } else {
      setTimes(undefined)
      setSelectedTime(undefined)
    }
  }

  const handleSelectedTime = (selectedTimeValue: string) => {
    setSelectedTime(selectedTimeValue)
  }

  const handleFullName = (event: ChangeEvent<HTMLInputElement>) => {
    fullName.current = event.target.value
  }

  const handlePhoneNumber = (event: ChangeEvent<HTMLInputElement>) => {
    phoneNumber.current = event.target.value
  }

  const submitHandler = async () => {
    if (
      !selectedDate ||
      !selectedTime ||
      fullName.current === "" ||
      phoneNumber.current === ""
    ) {
      dispatch(setErrorMessage("Missing Values"))
      return
    }

    console.log(selectedDate, selectedTime, fullName, phoneNumber)
    //TODO change the true to match the booknow function
    const bookingRespond = await bookNow(
      selectedDate,
      selectedTime,
      true,
      fullName.current,
      phoneNumber.current
    )

    if ("error" in bookingRespond) {
      setErrorMessage(bookingRespond.message)
    } else {
      if (selectedDay) fetchAvailableTimes(selectedDay)
      // const date = String(getSelectedDate());
      // const time = String(selectedTime);
      // const msg = formatMessage({ id: 'bookingSuccessfullyMessage' },
      //     { date: date, time: time });
      // setSuccessfullyMessage(msg);
    }
  }

  return (
    <BookingBoxContainer>
      <HeaderStyle>
        <WeekHeader />
      </HeaderStyle>
      <BookingBodyContainer>
        <BoxTitleStyle>
          <FormattedMessage id="available_times" />
        </BoxTitleStyle>
        <ShiftsList
          selected={selectedTime}
          shifts={times}
          onClick={(shift: string) => handleSelectedTime(shift)}
        />
        <InputsContainer>
          <InputContainer>
            <InputTitleStyle>
              <FormattedMessage id="full_name" />:
            </InputTitleStyle>
            <InputValueStyle onChange={handleFullName} />
          </InputContainer>
          <InputContainer>
            <InputTitleStyle>
              <FormattedMessage id="phone_number" />:
            </InputTitleStyle>
            <InputValueStyle onChange={handlePhoneNumber} />
          </InputContainer>
          <SubmitButtonStyle onClick={submitHandler}>
            <FormattedMessage id="submit" />
          </SubmitButtonStyle>
        </InputsContainer>
      </BookingBodyContainer>
    </BookingBoxContainer>
  )
}

export default BookingBox
