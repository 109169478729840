import styled, { css } from "styled-components";

export const BackgroundPopupStyle = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
`

export const PopupWindowStyle = styled.div<{ isVisible: boolean }>`
  position: absolute;
  display: flex;
  z-index: 3;
  flex-direction: column;
  width: 90%;
  max-width: 330px;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #E64840 0%, rgba(230, 72, 64, 0.92) 100%) ;
  border: 1px solid #000000;
  border-radius: 10px;
  backdrop-filter: blur(2px);
  gap: 28px;
  padding: 30px 0;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  opacity: 0;
  transform: translateY(50px);
  transition: all 0.3s ease-out;

  ${({ isVisible }) => isVisible && css`
    opacity: 1;
    transform: translate(-50%, -50%);
  `}
`

export const BackPopupWindowStyle = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

export const MessageContainerStyle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Jost', sans-serif;
    font-size: 1.5em;
    color: #fff;
`

export const MessageTextStyle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 10px 0 25px;
`

export const MessageHeaderStyle = styled.div`
  display: flex;
  gap:10px;

`

export const MessageBodyStyle = styled.div`

`

export const OkLogo = styled.img`
  width: 40px;
  height: 40px;
  color: #fff;
  background-color: transparent;
`;


export const ERRORMessageStyle = styled.div`
  font-size: 25px;
  white-space: pre-line;
  color: #FFF;
  text-align: center;
`

export const SuccessfullyMessageStyle = styled.div`
  font-size: 25px;
  white-space: pre-line;
  color: #FFF;
  text-shadow: 1px 1px black;
  font-family: 'Jost',sans-serif;
  font-size: normal;
  text-align: center;
`

export const ButtonsContainerStyle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: 50px;
`

export const ConfirmButtonStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  border: 2px solid #222532;
  background: #222532;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 100%;
  width: 40%;
  color: #fff;
  

`;

export const CancelButtonStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 40%;
  border-radius: 40px;
  border: 2px solid #222532;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  
`;

export const CloseButtonStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    margin: 20px 20% 0;
    height: 40px;
    width: 60%;
    border-radius: 40px;
`

export const BackDivBlurred = styled.div`
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  position: absolute;
  z-index: 10;
  /* filter: blur(20px);
  display: flex; */
  
`;
