import React, { useEffect, useState } from "react"
import {
  AppointmentCard,
  AppointmentCardButton,
  AppointmentHeader,
  AppointmentTime,
  AppointmentTimeFrom,
  ButtonsLayout,
  CurrentAppointments,
  ContentUserData,
  MenuContainer,
  TimeAndButtonLayout,
  UserAppointmentsFooter,
  UserAppointmentsHeader,
  UserAppointmentsMainContainer,
  UserAppointmentsMainContent,
  UserDataHeader,
  BlurredBackGround,
  CancelButtonStyle,
  ConfirmButtonStyle,
  PopUpDeleteMessage,
  PopUpHeaderDeleteConfirmMsg,
  DeleteButtonsContainer
} from "./Styles"
import Header from "./header/Header"
import reminderLogo from "../../components/assets/icons/RemindMeLogo.svg"
import DeleteLogo from "../../components/assets/icons/DeleteAppointmentLogo.svg"
import EditLogo from "../../components/assets/icons/EditAppointmentLogo.svg"
import { FormattedMessage } from "react-intl"
import Menu from "../menuPage/menuPage"
import {
  deleteAppointment,
  getUserAppointments
} from "../../services/BackendServices"
import AppointmentsType from "../../types/rest/AppointmentsType"
import RestRespondType from "../../types/rest/RestRespondType"
import { useSelector } from "react-redux"
import { getSelectedLanguage } from "../../store/languagesSlice"
import BookNowPanel from "../../containers/bookNowPanel/BookNowPanel"
const UserAppointments = () => {
  const selectedLanguage = useSelector(getSelectedLanguage)
  const [appointmentsId, setAppointmentsId] = useState<string | false>(false)
  const [userData, setUserData] = useState<AppointmentsType | undefined>(
    undefined
  )
  const [isDisplayed, setIsDisplayed] = useState<boolean>(true)
  const [EditBookPanel, setEditBookPanel] = useState<boolean>(false)
  const [deletedAppointmentId, setDeletedAppointmentId] = useState<string>("")
  const [DeleteMessageDisplay, setDeleteMessageDisplay] = useState<boolean>(false)

  useEffect(() => {
    fetchUserAppointments()
  }, [selectedLanguage, EditBookPanel,DeleteMessageDisplay])

  const fetchUserAppointments = async () => {
    const userAppintments: AppointmentsType | RestRespondType =
      await getUserAppointments()
    if ("appointments" in userAppintments) {
      setUserData(userAppintments)
    } else {
      setUserData(undefined)
    }
  }

  const EditBooking = async (appointmentId: string) => {
    setAppointmentsId(appointmentId)
    OpenPanel()
  }

  const DeleteBooking = (appointmentId: string) => {
    deleteAppointment(appointmentId)
  }

  const closePanel = () => {
    setEditBookPanel(false)
    setAppointmentsId(false)
  }

  const OpenPanel = () => {
    setEditBookPanel(true)
  }

  const closeDeletePanel = () => {
    setDeleteMessageDisplay(false)
    setDeletedAppointmentId("")
    fetchUserAppointments()
  }

  const OpenDeletePanel = (appointmentId: string) => {
    setDeletedAppointmentId(appointmentId)
    setDeleteMessageDisplay(true)
  }

  const openMenu = () => {
    setIsDisplayed(false)
  }

  const closeMenu = () => {
    setIsDisplayed(true)
  }

  const getAppointments = () => {
    if (userData?.appointments[0]) {
      return userData.appointments.map((appointment, index) => {
        return (
          <AppointmentCard key={index}>
            <AppointmentHeader>
              <FormattedMessage id={appointment.monthName} />
              {", "}
              {appointment.BookingDay} {appointment.dayOfWeek}{" "}
            </AppointmentHeader>
            <TimeAndButtonLayout>
              <AppointmentTime>
                <AppointmentTimeFrom>
                  {appointment.BookingTime}{" "}
                  {/* <FormattedMessage id={appointment.timeSlot.from.meridiem} /> */}
                </AppointmentTimeFrom>
              </AppointmentTime>
              <ButtonsLayout>
                <AppointmentCardButton
                  src={EditLogo}
                  alt="Edit"
                  onClick={() => EditBooking(appointment.id)}
                />
                <AppointmentCardButton
                  src={DeleteLogo}
                  alt="Delete"
                  onClick={() => OpenDeletePanel(appointment.id)}
                />
              </ButtonsLayout>
            </TimeAndButtonLayout>
          </AppointmentCard>
        )
      })
    } else
      return (
        <AppointmentCard>
          <AppointmentHeader>No Appointments yet</AppointmentHeader>
        </AppointmentCard>
      )
  }

  return (
    <>
      <MenuContainer>
        <Menu onClose={closeMenu} />
      </MenuContainer>
      {
        <BookNowPanel
          closeFunc={closePanel}
          isOpen={appointmentsId ? true : false}
          isEdit={appointmentsId}
        />
      }
      {DeleteMessageDisplay && (
        <BlurredBackGround>
          <PopUpDeleteMessage>
            <PopUpHeaderDeleteConfirmMsg>
              Are you sure you want to delete?
            </PopUpHeaderDeleteConfirmMsg>
            <DeleteButtonsContainer>
              <ConfirmButtonStyle
                onClick={() => {DeleteBooking(deletedAppointmentId)
                closeDeletePanel()}}
              >
                <FormattedMessage id="confirm" />
              </ConfirmButtonStyle>
              <CancelButtonStyle onClick={closeDeletePanel}>
                <FormattedMessage id="cancel" />
              </CancelButtonStyle>
            </DeleteButtonsContainer>
          </PopUpDeleteMessage>
        </BlurredBackGround>
      )}
      <UserAppointmentsMainContainer isDisplayed={isDisplayed}>
        <UserAppointmentsHeader>
          {/* //TODO Header */}
          <Header onOpen={openMenu} />
        </UserAppointmentsHeader>
        <UserAppointmentsMainContent>
          {/*//TODO Main Content */}
          <ContentUserData>
            <UserDataHeader>
              {userData?.user.fullName ? (
                <>
                  <FormattedMessage id={`Hello`} />, {userData?.user.fullName}
                </>
              ) : (
                <></>
              )}
            </UserDataHeader>
          </ContentUserData>

          <CurrentAppointments>{getAppointments()}</CurrentAppointments>
        </UserAppointmentsMainContent>
      </UserAppointmentsMainContainer>
    </>
  )
}

export default UserAppointments
