import styled from "styled-components";
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import calender_icon from "../../components/assets/icons/calender_icon.svg";

interface CellTableProps {
  isClicked?: boolean;
  isDisabled?: boolean;
}

interface ProgressBoxProps {
  loadingPercentage: number;
}

export const TopScreenStyle = styled.div`
  height: 50%;
`

export const DownScreenStyle = styled.div`
  height: 50%;
  width: 100%;
`

export const ButtonsCountainerStyle = styled.div`
  position: absolute;
  bottom: 5%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`

export const ScrollWindowBackgroundStyle = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
`

export const ScrollWindowContainerStyle = styled.div`
  direction: ltr;
  position: fixed;
  z-index: 1;
  background-color: aliceblue;
  /* overflow-y: auto; */
  width: 100%;
  bottom: 0;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translateX(-50%);
  max-width: 450px;
  transition: height 1s ease-in-out;
  height: ${props => props.className === "moving" ? '90%' : '0'};
  border-radius: 50px 50px 0px 0px;
  border: 3px solid #000;
  background: linear-gradient(180deg, rgba(165, 52, 52, 0.76) 0%, #A53434 5.93%, #CE4949 18.63%, #A14B4B 35.84%, #942C2C 58.85%, #5A3F3F 75.21%, rgba(141, 59, 59, 0.9) 100%);  
  ::-webkit-scrollbar {
    display: none;
  }
`

export const CloseScrollButtonStyle = styled.div`
  display: flex;
  position: sticky;
  top: 10px;
  justify-content: center;
  margin: 10px 0 0;
  width: 100%;
  z-index: 2;
`

export const CloseButtonStyle = styled.img`

`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin:  10px 25px;
  /* position: auto; */
  top:0;
`

export const MonthTitleStyle = styled.div`
  color: #FFFEFE;
  text-align: center;
  font-family: 'Jost', sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const DateSwitchContainer = styled.div`
  display: flex;
  gap: 26px;
`

export const DateSwitchButtonStyle = styled.img`
  
`

export const SideTitleContainer = styled.div`
  display: flex;
  padding: 5px 0 5px 22px;
  border-radius: 0 14px 14px 0;
  background: rgba(176, 116, 116, 0.59);
  align-items: center;
  color: #B8B3B3;
  font-family: 'Jost', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 94px;
  gap: 10px;
`

export const VerticalLineStyle = styled.div`
  width: 3px;
  height: 36px; 
  background: #FFF;
`

export const TableStyle = styled.div`
  padding: 0 10px;
`

export const RowTableStyle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

export const ProgressBoxStyle = styled(Box)<ProgressBoxProps>(({ loadingPercentage }) => {
  const degree = (loadingPercentage / 100) * 360;
  return {
    position: 'relative',
    height: '78px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: `conic-gradient(${degree!==0?"#43b53f":"#FF3333"} 0deg ${degree!==0?degree:360}deg, transparent ${degree!==0?degree:360}deg 360deg)`,
      borderRadius: '10px',
      zIndex: 10,
    },
    '& > div': {
      position: 'relative',
      width: 'calc(100% - 5px)',
      height: 'calc(100% - 5px)',
      backgroundColor: 'red',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 15,
    },
  };
});

export const CellTableStyle = styled.div<CellTableProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 13px 0;
  width: 45px;
  gap: 5px;
  border-radius: 10px;
  border: 0.5px solid #FFF;
  background-color: ${props => props.isDisabled ? '#838181' : (props.isClicked ? '#F6F6F6' : '#161616')};
`


export const CellDateLabelStyle = styled.div<CellTableProps>`
  color: ${(props) => (props.isClicked ? '#0E0E0E' : '#FFF')};
  font-family: 'Jost', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const CellDOFLabelStyle = styled.div<CellTableProps>`
  color: ${(props) => (props.isClicked ? '#0E0E0E' : '#FFF')};
  font-family: 'Jost', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const DateOptionStyle = styled.div<CellTableProps>`
  background: ${(props) => (props.isClicked ? '#FF4F2D' : '#E5C4B7')};
  position: relative;
  width: 35px;
  padding: 3px 0;
  margin: 0 1px 4px;
  border-radius: 25px;
  text-align: center;
`

export const DateTimeOptionsContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* min-height: calc(100% - 110px); */
  overflow-y:auto;
  position: relative;
  gap:10px;
`

export const DateContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const TimeOptionsStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-x: auto;
  font-family: 'Jost', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;

  ::-webkit-scrollbar {
    display: none;
  }
`

export const TimeOptionStyle = styled.div<CellTableProps>`
  background: ${(props) => (props.isClicked ? '#FF4F2D' : '#E5C4B7')};
  border: 3px solid #304F9C;
  border-radius: 6px;
  padding: 5px;
`

export const TimeContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background: #171717;
  margin: 0 20%;
  padding:5px 10px;
  height: 96px; 
  gap: 10px;
  font-family: 'Jost', sans-serif;
`

export const TimeTitleStyle = styled.div`
  color: #FFF;
  text-align: center;
  font-family: 'Jost', sans-serif;
    font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const TimeDropdownContainer = styled.div`
  background: rgba(217, 217, 217, 0.00);
  color: #FFF;
  text-align: center;
  font-family: 'Jost', sans-serif;
    font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

interface ComboBoxProps {
  isOpen: boolean;
  selectedItem: null|string;
}

export const TimeSelectedItemStyle = styled.div<ComboBoxProps>`
  display: inline-flex;
  min-width: 50px;
  padding: 5px 5px;
  gap: 10px;
  border-radius: 15px;
  border: 1px solid #FFF;
  cursor: pointer;
`;

export const TimeDropdownListContainer = styled(Dialog)`
  .MuiDialog-paper {
    height: 50%;
    margin: 0;         // Reset margin
    padding: 0;        // Reset padding
    overflow-y: auto;  // Enable scrolling
  }
`;

export const TimeDropdownListStyle = styled(List)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  width: 140px;
`;

export const TimeDropdownItemStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }

  text-align: center;
  font-family: 'Jost', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const KidsOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const KidsOptionStyle = styled.div`
  display: flex;
  padding-left: 40px;
  gap: 11px;
`

export const HiddenCheckboxStyle = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid #FFF;
  border-radius: 4px;
  background-color: transparent;
  flex-shrink: 0;

  /* Style for checked state */
  ${HiddenCheckboxStyle}:checked + & {
    background-color: #FFF;
    /* You can add additional styles for checked state here */
  }
`;

export const CheckboxLabelStyle = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const KidsOptionDescriptionStyle = styled.div`
  color: #FFFEFE;
  text-align: center;
  font-family: 'Jost', sans-serif;
    font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

/*****************Book Now Card*******************/

export const BookNowContainerStyle = styled.div`
border-radius: 40px 40px 0px 0px;
background: #E8E8E8;
height: auto;
max-height :11em;
position: auto;
bottom: 0;
  overflow-y: none;

`

export const BookNowCardStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const CalenderIconImage = styled.img.attrs({ src: calender_icon })`
  width: 24px;
  height: 24px;
`

export const SelectedTimeStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 25px;
`

export const SelectedTimeCellStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  font-family: 'Jost', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 39px;
  /* identical to box height */
  text-align: center;
`

export const SelectedTimeCellTitleStyle = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  color: #D9D9D9;
`

export const SelectedDateCellValueStyle = styled.div`
  color: #0E0E0E;
  text-align: center;
  font-family: 'Jost', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const SelectedTimeCellValueStyle = styled.div`
  color: #656565;
  text-align: center;
  font-family: 'Jost', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const BookNowButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px 40px 0px 0px;
  background: #FFFCFC;
  height: 8em; // white book now button
  /* max-height:7em ; */
  position: relative;
  bottom: 0;
`

export const BookNowButtonStyle = styled.input`
  height: 55px;
  width: 100%;
  margin: 0 60px;
  background-color: ${props => (props.disabled ? '#ccc' : '#E5C4B7')};
  border-radius: 40px;
  background: #0E0E0E; 

  color: #FFFEFE;
  text-align: center;
  font-family: 'Jost', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; 
`

export const HrStyle = styled.div`
  background-color: #000000;
  width: 1px;
  height: 100%;
`

export const SvgCircle = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-90deg) scale(1, -1);
  width: 36px;
  height: 36px;
`

interface progress {
  percentage: number;
}

export const CircleProgress = styled.circle<progress>`
  animation-name: mat-progress-spinner-stroke-rotate-100;
  stroke-dasharray: ${prop => 100 + prop.percentage}px;
  stroke-dashoffset: 100px;
  stroke-width: 4px;
`

export const TimeImg = styled.img`
  height: 100%;
`;
