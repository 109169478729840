import { useEffect, useState } from "react"

import MainSlider from "../../components/mainSlider/mainSlider"
import PhotosSlider from "../../components/photosSlider/PhotosSlider"
import MediaType from "../../types/rest/MediaType"
import getMedia from "../../services/BackendServices"
import { HomeMainDiv, MainHomeContainer, MainHomeContainerFixed } from "./Styles"

const Home = () => {
  const [insideShop, setInsideShop] = useState<MediaType[]>([])
  const [hairCuts, setHairCuts] = useState<MediaType[]>([])
  const [Store, setStore] = useState<MediaType[]>([])

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    try {
      const InsideShop = await getMedia("InsideShop")
      const hairCuts = await getMedia("HairCuts")
      const Store = await getMedia("Store")
      setInsideShop(InsideShop)
      setHairCuts(hairCuts)
      setStore(Store)
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error)
    }
  }

  return (
    <MainHomeContainerFixed>
      <MainHomeContainer>
        <MainSlider />
        <HomeMainDiv>
          <PhotosSlider headerText="InsideShop" images={insideShop} />
          <PhotosSlider headerText="HairCuts" images={hairCuts} />
          <PhotosSlider headerText="Store" images={Store} />
        </HomeMainDiv>
      </MainHomeContainer>
    </MainHomeContainerFixed>
  )
}

export default Home
