import React, { useEffect, useState } from "react"
import {
  ButtonLogo,
  DividerContainer,
  HamburgerXLogo,
  MenuButton,
  MenuDivider,
  MenuLeftLogo,
  MenuListDiv,
  MenuMainContainer,
  OnClickButton,
  OneMenuButtonContainer,
  OtherSettingDiv,
  OtherSettingTemmplate,
  OtherSettingImage,
  OtherSettingtext,
  SocialImage,
  SocialMediaDiv,
  BackgroundLinesImage,
  MenuHeaderContainer,
  SocialInstaGramHandler,
} from "./Styles"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import menuLeftLogo from "../../components/assets/icons/MenuLogoLeft.svg"
import hamburgerXLogo from "../../components/assets/icons/HamburgerX.svg"
import MenuHome from "../../components/assets/icons/MenuHome.svg"
import MenuControl from "../../components/assets/icons/MenuControl.svg"
import MenuLogOut from "../../components/assets/icons/MenuLogOut.svg"
import MenuContactUs from "../../components/assets/icons/MenuContactUs.svg"
import MenuLocation from "../../components/assets/icons/MenuLocation.svg"
import MenuGallery from "../../components/assets/icons/MenuGallery.svg"
import MenuSupport from "../../components/assets/icons/MenuSupport.svg"
import MenuBookNow from "../../components/assets/icons/MenuBookNow.svg"
import MenuLogo1 from "../../components/assets/icons/MenuLogo1.svg"
import MenuLogo2 from "../../components/assets/icons/MenuLogo2.svg"
import MenuLogo3 from "../../components/assets/icons/MenuLogo3.svg"
import MenuHelpCenter from "../../components/assets/icons/MenuHelpCenter.svg"
import MenuMyAppointment from "../../components/assets/icons/appointmentLogo.svg"
import MenuSetting from "../../components/assets/icons/MenuSetting.svg"
import MenuLogin from "../../components/assets/icons/MenuLogin.svg"
import background from "../../components/assets/images/Background Lines.svg"
import { useLocation } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { isLoggedIn } from "../../store/authSlice"

interface IMenuProps {
  onClose: () => void
}

const Menu: React.FC<IMenuProps> = ({ onClose }) => {
  const location = useLocation()
  const { pathname } = location
  const [currentPage, setCurrentPage] = useState<string>(pathname)

  const { role, isLogged } = useSelector((state: RootState) => state.auth)
  const isLoggedInStatus = useSelector(isLoggedIn)

  // const fixedLocation = '37.7749,-122.4194'; // Replace with your fixed location coordinates

  const openMapsApp = () => {
    // Construct the maps URL with the fixed location
    // const mapsUrl = `https://maps.google.com/maps?q=${fixedLocation}`;
    const mapsUrl = "https://waze.com/ul/hsvc1gvs3m"
    // Open the URL in a new tab/window
    window.open(mapsUrl, "_blank")
  }

  const opneFaceBook = () => {
    // Construct the maps URL with the fixed location
    // const mapsUrl = `https://maps.google.com/maps?q=${fixedLocation}`;
    const Url = "https://www.facebook.com/profile.php?id=100007386392512&mibextid=LQQJ4d"
    // Open the URL in a new tab/window
    window.open(Url, "_blank")
  }

  const openInstagramApp = () => {
    // Construct the maps URL with the fixed location
    // const mapsUrl = `https://maps.google.com/maps?q=${fixedLocation}`;
    const Url = "https://instagram.com/yousef.darawshe"
    // Open the URL in a new tab/window
    window.open(Url, "_blank")
  }

  let publicRoutes = [
    {
      path: "/home",
      name: "home",
      internal: false
    },
    {
      path: "/",
      name: "book_now",
      internal: false
    },
    {
      path: "",
      name: "location",
      internal: true
    },
    {
      path: "/gallery",
      name: "gallery",
      internal: false
    },
    {
      path: "/contact_us",
      name: "contact_us",
      internal: false
    },
    {
      path: "/support",
      name: "support",
      internal: false
    }
  ]

  let adminRoutes = [
    {
      path: "/control",
      name: "control",
      internal: false
    }
  ]

  let userRoutes = [
    {
      path: "/logout",
      name: "logout",
      internal: false
    },
    {
      path: "/userappointments",
      name: "UserAppointments",
      internal: false
    }
  ]

  let unsignedUserRoutes = [
    {
      path: "/login",
      name: "login",
      internal: false
    }
  ]

  // const optionsList = () => {
  //   const routes = [
  //     ...publicRoutes,
  //     ...(role === "admin" ? adminRoutes : []),
  //     ...(isLogged ? userRoutes : unsignedUserRoutes)
  //   ]

  const optionsList = () => {
    let routes
    try {
      if (role === "admin") {
        routes = [
          {
            path: "/home",
            name: "home",
            internal: false
          },
          {
            path: "/control",
            name: "control",
            internal: false
          },
          {
            path: "/",
            name: "book_now",
            internal: false
          },
          {
            path: "",
            name: "location",
            internal: true
          },
          // {
          //   path: "/gallery",
          //   name: "gallery",
          //   internal: false
          // },
          // {
          //   path: "/contact_us",
          //   name: "contact_us",
          //   internal: false
          // },
          {
            path: "/logout",
            name: "logout",
            internal: false
          }
        ]
      } else if (isLoggedInStatus) {
        routes = [
          {
            path: "/home",
            name: "home",
            internal: false
          },
          {
            path: "/",
            name: "book_now",
            internal: false
          },
          {
            path: "/userappointments",
            name: "UserAppointments",
            internal: false
          },
          {
            path: "",
            name: "location",
            internal: true
          },
          // {
          //   path: "/gallery",
          //   name: "gallery",
          //   internal: false
          // },
          // {
          //   path: "/contact_us",
          //   name: "contact_us",
          //   internal: false
          // },
          {
            path: "/logout",
            name: "logout",
            internal: false
          }
        ]
      } else {
        routes = [
          {
            path: "/home",
            name: "home",
            internal: false
          },
          {
            path: "/",
            name: "book_now",
            internal: false
          },
          {
            path: "",
            name: "location",
            internal: true
          },
          // {
          //   path: "/gallery",
          //   name: "gallery",
          //   internal: false
          // },
          // {
          //   path: "/contact_us",
          //   name: "contact_us",
          //   internal: false
          // },
          {
            path: "/login",
            name: "login",
            internal: false
          }
        ]
      }

      return routes.flatMap(
        (route: { path: string; name: string; internal: boolean }) => {
          const isOpened = route.path === currentPage
          if (route.internal) {
            return (
              <OnClickButton clicked={isOpened}>
                <OneMenuButtonContainer to={route.path} onClick={openMapsApp}>
                  <ButtonLogo src={getIcon(route.name)} />
                  <MenuButton>
                    <FormattedMessage id={route.name} />
                  </MenuButton>
                </OneMenuButtonContainer>
              </OnClickButton>
            )
          } else {
            return (
              <OnClickButton clicked={isOpened}>
                <OneMenuButtonContainer to={route.path} onClick={onClose}>
                  <ButtonLogo src={getIcon(route.name)} />
                  <MenuButton>
                    <FormattedMessage id={route.name} />
                  </MenuButton>
                </OneMenuButtonContainer>
              </OnClickButton>
            )
          }
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  const getIcon = (label: string) => {
    switch (label) {
      case "home":
        return MenuHome
      case "control":
        return MenuControl
      case "logout":
        return MenuLogOut
      case "book_now":
        return MenuBookNow
      case "location":
        return MenuLocation
      case "gallery":
        return MenuGallery
      case "contact_us":
        return MenuContactUs
      case "support":
        return MenuSupport
      case "login":
        return MenuLogin
      case "UserAppointments":
        return MenuMyAppointment
    }
    return MenuHome
  }

  return (
    <MenuMainContainer>
      <MenuHeaderContainer>
        <MenuLeftLogo src={menuLeftLogo} />
        <HamburgerXLogo src={hamburgerXLogo} onClick={onClose} />
      </MenuHeaderContainer>
      <BackgroundLinesImage src={background} />
      {/* // TODO   logo left side w pondDrop effect + hamburger right side(new)  */}
      <MenuListDiv>{optionsList()}</MenuListDiv>
      <DividerContainer>
        <MenuDivider />
      </DividerContainer>

      <OtherSettingDiv>
        <OtherSettingTemmplate>
          <OtherSettingImage src={MenuSetting} />
          <OtherSettingtext>
            <FormattedMessage id={"setting"} />
          </OtherSettingtext>
        </OtherSettingTemmplate>
        <OtherSettingTemmplate>
          <OtherSettingImage src={MenuHelpCenter} />
          <OtherSettingtext>
            <FormattedMessage id={"help_center"} />
          </OtherSettingtext>
        </OtherSettingTemmplate>
      </OtherSettingDiv>

      <SocialMediaDiv>
        {/* <SocialImage src={MenuLogo1} /> */}
        <SocialInstaGramHandler onClick={openInstagramApp}/>
        {/* <SocialImage src={MenuLogo2} /> */}
        <SocialImage src={MenuLogo3} onClick={opneFaceBook}/>
      </SocialMediaDiv>
    </MenuMainContainer>
  )
}

export default Menu
