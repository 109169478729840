import React, { useRef, useState } from 'react';
import { Dot, DotsContainer, PhotoStyle, SliderContainerStyle, SliderHeaderStyle, SliderStyle } from "./PhotosSlider.Style";
import ISliderProps from "./ISliderProps";
import { FormattedMessage } from "react-intl";
import ReactModal from 'react-modal';

const PhotosSlider: React.FC<ISliderProps> = ({ headerText, images }) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [isMoving, setIsMoving] = useState(false);
    const [startPosition, setStartPosition] = useState(0);
    const sliderRef = React.useRef<HTMLDivElement | null>(null);
    const [scrollLeft, setScrollLeft] = useState(0);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalImg, setModalImg] = useState('');


    const handleScroll = (e: any) => {
        const container = e.target;
        const visibleWidth = container.clientWidth;
        const scrollWidth = container.scrollWidth;
        const scrollPosition = container.scrollLeft;

        const newPosition = Math.round((scrollPosition / (scrollWidth - visibleWidth)) * (images.length - 1));
        setActiveSlide(newPosition);
    };

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setIsDragging(true);
        setStartPosition(e.pageX - sliderRef.current?.offsetLeft!);
        setScrollLeft(sliderRef.current?.scrollLeft!);
    };

    const handleMouseMove = (e: any) => {
        if (!isDragging || !sliderRef.current) return;
        setIsMoving(true);
        e.preventDefault();
        const x = e.pageX - sliderRef.current.offsetLeft;
        const scroll = x - startPosition;
        sliderRef.current.scrollLeft = scrollLeft - scroll;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        
    };

    const handleClickImage = (imgUrl: string) => {
        if(isMoving) {
            setIsMoving(false);
            return;
        };
        setModalOpen(true);
        setModalImg(imgUrl);
    }

    const handleCloseModal = () => {
        setModalOpen(false);
        setModalImg('');
    }

    return (
        <>
            <SliderContainerStyle>
                <SliderHeaderStyle>
                    {/* <FormattedMessage id={`${headerText}`} /> */}
                    <FormattedMessage id={`Label.${headerText}`} />
                </SliderHeaderStyle>
                <SliderStyle
                    ref={sliderRef}
                    onScroll={handleScroll}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                    onMouseMove={handleMouseMove}>
                    {images.map((image, index) => {
                        if (image && image.formats) {
                            const { small, medium, large, thumbnail } = image.formats;

                            let imgUrl;
                            if (small && small.url) {
                                imgUrl = small.url;
                            } else if (medium && medium.url) {
                                imgUrl = medium.url;
                            } else if (large && large.url) {
                                imgUrl = large.url;
                            } else if (thumbnail && thumbnail.url) {
                                imgUrl = thumbnail.url;
                            } else {
                                imgUrl = image.url;
                            }

                            return <PhotoStyle
                                key={index}
                                src={`${image.url}`}
                                draggable="false"
                                onClick={() => handleClickImage(image.url)}
                            />;
                        } else if (image && image.url) {
                            return <PhotoStyle
                                key={index}
                                src={`${image.url}`}
                                draggable="false"
                                onClick={() => handleClickImage(image.url)}
                            />
                        } else {
                            return null;
                        }
                    })}
                </SliderStyle>
                {/* <DotsContainer>
                    {images.map((_, index) => (
                        <Dot key={index} className={index === activeSlide ? 'active' : ''} />
                    ))}
                </DotsContainer> */}
            </SliderContainerStyle>
            <ReactModal
                isOpen={modalOpen}
                onRequestClose={handleCloseModal}
                style={{content: {maxWidth: '80%', maxHeight: '80%'}}}
            >
                <img src={modalImg} alt="" style={{width: '100%', height: 'auto'}} />
            </ReactModal>
        </>
    )
}

export default PhotosSlider;
