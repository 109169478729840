import styled from "styled-components";

export const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
`;

export const MovingImage = styled.img`
    position: relative;
    transition: top 1s ease-in-out, 
                width 1s ease-in-out, 
                max-width 1s ease-in-out, 
                max-height 1s ease-in-out;
    top: ${props => props.className === "moving" ? '30%' : '5%'};
    width: ${props => props.className === "moving" ? '80%' : '40%'};
    max-width: ${props => props.className === "moving" ? '282px' : '30%'};
    max-height: ${props => props.className === "moving" ? '333px' : '30%'};
`;