import styled, { css } from "styled-components";

import TextField from '@mui/material/TextField';
// import { withTheme } from '@mui/styles';

export const BackPopupWindowStyle = styled.div`
  position: absolute;
  display: flex;
  z-index: 1;
  height: 100%;
  width: 100%;
`

export const ParentContainer = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const PopupWindowStyle = styled.div<{ isVisible: boolean }>`
  position: fixed;    // changed from absolute to fixed
  display: flex;
  z-index: 3;
  flex-direction: column;
  width: 90%;
  max-width: 500px;
  justify-content: center;
  align-items: center;
  background: rgba(230, 72, 64, 0.92);
  border: 1px solid #000000;
  border-radius: 10px;
  backdrop-filter: blur(2px);
  gap: 28px;
  padding: 30px 0;
  top: 50%;           // changed from 20% to 50%
  left: 50%;          // added to center horizontally
  transform: translate(-50%, -50%);  // added to offset the top and left properties

  opacity: 0;
  transition: all 0.3s ease-out;

  ${({ isVisible }) => isVisible && css`
    opacity: 1;
    transform: translate(-50%, -50%);  // changed from translateY(0) to translate(-50%, -50%)
  `}
`

export const FormContainerStyle = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 28px;
`

export const InnerContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 5px;
  font-family: 'Jost', sans-serif;
`

export const PopupWindowTitleStyle = styled.div`
  color: #FFF;
  text-align: center;
  font-family: 'Jost', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  
`
export const PopupWindowSubTitleStyle = styled.div`
  color: #FFF;
  text-align: center;
  font-family: 'Jost', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const InputTextStyle = styled(TextField)`
  width: 80%;
  color: black;
  
`

export const LoginButtonStyle = styled.input`
  border-radius: 40px;
  border: 2px solid #222532;
  background: #222532;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  user-select: none; /*//! this might work to prevent div from having the crosshaid after being clicked, check later TODO */
  width: 80%;
  padding: 12px 0;

  color: #FFF;
  text-align: center;
  font-family: 'Jost', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`

export const RegisterButtonStyle = styled.div`
  background: rgba(230, 72, 64, 0.92);
  border: 1px solid #222532;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;

  width: 80%;
  padding: 12px 0;

  font-family: 'Jost', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  color: #FFFFFF;
  cursor: pointer;
`

export const ErrorMessageStyle = styled.div`
  font-family: 'Jost', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  /* identical to box height */
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #FF9900;
`

export const ForgetPasswordStyle = styled.div`
  font-family: 'Jost', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 5px;
  /* identical to box height */
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: white;
`

export const BlurredBackGroundDiv = styled.div`
width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  position: absolute;
  z-index: 10;
`;
