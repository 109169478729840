import styled from 'styled-components';

export const HomeMainDiv = styled.div`
    /* background: linear-gradient(180deg, #262732 0.96%, rgba(97, 53, 57, 0.96) 5.76%, rgba(136, 62, 62, 0.94) 10.57%, rgba(197, 76, 70, 0.90) 34.34%, rgba(38, 39, 50, 0.22) 78.66%), linear-gradient(152deg, rgba(197, 76, 70, 0.49) -0.08%, rgba(192, 79, 74, 0.76) 40.52%, rgba(147, 114, 112, 0.40) 125.64%); */
    background: linear-gradient(180deg, #262732 1.56%, rgba(97, 53, 57, 0.96) 10.83%, rgba(136, 62, 62, 0.94) 18.82%, #232532 43.83%, rgba(197, 76, 70, 0.53) 83.19%, rgba(163, 64, 62, 0.74) 93.48%, #8E3939 99.78%);
    flex-direction: column;
`;

export const MainHomeContainerFixed = styled.div`
    position: relative;
    width: 100%;
`;

export const MainHomeContainer = styled.div`
    position: sticky;
    top: 0;

`;


