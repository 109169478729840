import styled from 'styled-components';
import menu_button from "../../assets/icons/Ham_button.svg"

export const MenuButtonContainer = styled.div`
  position: absolute;
  direction:ltr;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: calc(100% - 1em);
  padding: 1em 0.5em;
  align-items: center;
  align-self: center;
  top: 0;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
`

export const MenuButtonStyle = styled.img.attrs({ src: menu_button })`
  position: relative;
  padding: 0px;
`

export const MyAppointmentsTextHeader = styled.div`
  color: #FF4F2D;
  font-family: 'Jost',sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
