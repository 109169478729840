import { useState } from "react";
import { BackgroundContainer, BookingButtonContainer, BookingButtonStyle, ButtonContainer, ButtonOptionContainer, ButtonOptionStyle, OptionLabelStyle } from "./Style";
import BookingOptionsIcon from "../assets/icons/BookingOptionsIcon.svg"
import AddIcon from "../assets/icons/AddIcon.svg";
import CancelIcon from "../assets/icons/XIcon.svg";
import BookingBox from "../bookingBox/BookingBox";
import { FormattedMessage } from "react-intl";

const BookingButton = () => {
    const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined)
    const [showOptions, setShowOptions] = useState(false);
    const options = ['cancel_all', 'add'];

    const getIcon = (option: string) => {
        switch (option) {
            case "add": return AddIcon;
            case "cancel_all": return CancelIcon;
        }
    }

    const getLabel = (option: string) => {
        switch (option) {
            case "add": return <FormattedMessage id="add" />;
            case "cancel_all": return <FormattedMessage id="cancel_all_appointments" />;
        }
    }

    const getOptionBox = () => {
        switch (selectedOption) {
            case "add": return <BookingBox />;
            case "cancel_all": return "Cancel All Appointments";
        }
    }

    const switchOptionsState = () => {
        setSelectedOption(undefined);
        setShowOptions(!showOptions);
    }

    return (
        <>
            {showOptions && <BackgroundContainer onClick={() => switchOptionsState()} />}
            {selectedOption && getOptionBox()}
            <ButtonContainer>
                <BookingButtonContainer onClick={() => switchOptionsState()}>
                    <BookingButtonStyle src={BookingOptionsIcon} />
                </BookingButtonContainer>
                {showOptions && options.map((option, index) => (
                    <ButtonOptionContainer key={option} index={index}
                        onClick={() => {setSelectedOption(option)
                        }}>
                        <ButtonOptionStyle src={getIcon(option)} />
                        <OptionLabelStyle>{getLabel(option)}</OptionLabelStyle>
                    </ButtonOptionContainer>
                ))}
            </ButtonContainer>
        </>
    )
}

export default BookingButton;