import { useEffect, useRef, useState } from "react"
import CloseButton from "../../components/assets/icons/Close_Rectangle.svg"
import BookNowPanelProps from "./BookNowPanelProps"
import {
  BookNowButtonContainer,
  BookNowButtonStyle,
  BookNowCardStyle,
  BookNowContainerStyle,
  CalenderIconImage,
  CellDateLabelStyle,
  CellDOFLabelStyle,
  CellTableStyle,
  CheckboxLabelStyle,
  CloseButtonStyle,
  CloseScrollButtonStyle,
  DateContainerStyle,
  DateSwitchContainer,
  DateTimeOptionsContainerStyle,
  HeaderContainer,
  HiddenCheckboxStyle,
  KidsOptionContainer,
  KidsOptionDescriptionStyle,
  KidsOptionStyle,
  MonthTitleStyle,
  ProgressBoxStyle,
  RowTableStyle,
  ScrollWindowBackgroundStyle,
  ScrollWindowContainerStyle,
  SelectedDateCellValueStyle,
  SelectedTimeCellStyle,
  SelectedTimeCellValueStyle,
  SelectedTimeStyle,
  SideTitleContainer,
  StyledCheckbox,
  TableStyle,
  TimeContainerStyle,
  TimeDropdownContainer,
  TimeDropdownItemStyle,
  TimeDropdownListContainer,
  TimeDropdownListStyle,
  TimeOptionsStyle,
  TimeSelectedItemStyle,
  TimeTitleStyle,
  VerticalLineStyle
} from "./Styles"
import {
  bookNow,
  editBook,
  getAvailableTimes
} from "../../services/BackendServices"
import ConfirmMessage from "../../components/confirmMessage/ConfirmMessage"
import AvailableTimeType, {
  AvailableTime
} from "../../types/rest/AvailableTimeType"
import AvailableTimeFailedType from "../../types/rest/AvailableTimeFailedType"
import { FormattedMessage, useIntl } from "react-intl"
import arrow_down_icon from "../../components/assets/icons/arrow_down_icon.svg"
import clock_icon from "../../components/assets/icons/clock_icon.svg"
import { getSelectedLanguage } from "../../store/languagesSlice"
import Box from "@mui/material/Box"
import { useSelector } from "react-redux"

const useAvailableTimes = (inProgress: boolean) => {
  const [datesTimes, setDatesTimes] = useState<AvailableTimeType | null>(null)
  const selectedLanguage = useSelector(getSelectedLanguage)

  useEffect(() => {
    const fetchAvailableTimes = async () => {
      const availableTime: AvailableTimeFailedType | AvailableTimeType =
        await getAvailableTimes()
      if (availableTime.statusCode === 200) {
        "body" in availableTime && setDatesTimes(availableTime)
      } else {
        setDatesTimes(null)
      }
    }

    if (!inProgress) {
      fetchAvailableTimes()
    }
  }, [inProgress, selectedLanguage])

  return datesTimes
}

interface SelectDialogProps {
  options: string[]
  onSelect: (option: string) => void
  open: boolean
  onClose: () => void
}

const SelectDialog: React.FC<SelectDialogProps> = ({
  options,
  onSelect,
  open,
  onClose
}) => {
  if (options.length === 0) {
    onClose()
    return <></>
  }
  return (
    <TimeDropdownListContainer onClose={onClose} open={open}>
      <TimeDropdownListStyle>
        {options.map((option, index) => (
          <TimeDropdownItemStyle onClick={() => onSelect(option)} key={index}>
            <div>{option}</div>
          </TimeDropdownItemStyle>
        ))}
      </TimeDropdownListStyle>
    </TimeDropdownListContainer>
  )
}

const BookNowPanel: React.FC<BookNowPanelProps> = ({
  closeFunc,
  isOpen,
  isEdit
}) => {
  const { formatMessage } = useIntl()

  const [inProgress, setInProgress] = useState<boolean>(false)
  let datesTimes = useAvailableTimes(inProgress)

  const [selectedDay, setSelectedDay] = useState<AvailableTime>()
  const [selectedTime, setSelectedTime] = useState<string | null>(null)
  const bookNowEnable = useRef<boolean>(false)

  const [adultsSelected, setAdultsSelected] = useState<boolean>(true)
  const [isOpenTimeSelection, setIsOpenTimeSelection] = useState<boolean>(false)
  const ref = useRef(null)

  const [errorMessage, setErrorMessage] = useState<String | null>(null)
  const [successfullyMessage, setSuccessfullyMessage] = useState<String | null>(
    null
  )

  useEffect(() => {
    if (datesTimes && selectedDay) {
      const updatedDay = datesTimes.body.availableTime.find(
        (day) => day.date === selectedDay.date
      )
      setSelectedDay(updatedDay)
    } else if (datesTimes) {
      const firstAvailableDay = datesTimes.body.availableTime.find(
        (day) => day.timeList.length !== 0
      )
      if (firstAvailableDay !== undefined) {
        setSelectedDay(firstAvailableDay)
        setSelectedTime(firstAvailableDay.timeList[0])
        bookNowEnable.current = true
      }
    }
  }, [datesTimes])

  const selectDay = (day: AvailableTime) => {
    if (inProgress) return
    setSelectedDay(day)
    if (day.timeList.length > 0) {
      const firstTimeInDay = day.timeList.at(0)
      if (firstTimeInDay) {
        setSelectedTime(firstTimeInDay)
      }
      bookNowEnable.current = true
    } else {
      setSelectedTime("")
      bookNowEnable.current = false
    }
  }

  const selectTime = (time: string) => {
    if (inProgress) return
    setSelectedTime(time)
    setIsOpenTimeSelection(false)
  }

  const closePanel = () => {
    closeFunc()
  }

  const getDates = () => {
    return (
      datesTimes &&
      datesTimes.body.availableTime.map((date) => {
        const isSelected = selectedDay?.dateValue === date.dateValue
        const isDisabled = date.timeList && date.timeList.length === 0
        return (
          <ProgressBoxStyle loadingPercentage={date.reservedPercent}>
            <Box>
              {isDisabled && (
                <CellTableStyle isClicked={isSelected} isDisabled={isDisabled}>
                  <CellDateLabelStyle isClicked={isSelected}>
                    {date.dateValue}
                  </CellDateLabelStyle>
                  <CellDOFLabelStyle isClicked={isSelected}>
                    {date.dayOfWeek.toUpperCase()}
                  </CellDOFLabelStyle>
                </CellTableStyle>
              )}
              {!isDisabled && (
                <CellTableStyle
                  isClicked={isSelected}
                  isDisabled={isDisabled}
                  onClick={(isDisabled) => {
                    if (isDisabled) selectDay(date)
                  }}
                >
                  <CellDateLabelStyle isClicked={isSelected}>
                    {date.dateValue}
                  </CellDateLabelStyle>
                  <CellDOFLabelStyle isClicked={isSelected}>
                    {date.dayOfWeek.toUpperCase()}
                  </CellDOFLabelStyle>
                </CellTableStyle>
              )}
            </Box>
          </ProgressBoxStyle>
        )
      })
    )

    // <CellTableStyle isClicked={isSelected} onClick={() => selectDay(date)}>
    //     <CellDateLabelStyle isClicked={isSelected}>{date.dateValue}</CellDateLabelStyle>
    //     <CellDOFLabelStyle isClicked={isSelected}>{date.dayOfWeek.toUpperCase()}</CellDOFLabelStyle>
    // </CellTableStyle>
  }

  const getTimes = () => {
    return (
      <>
        {
          <TimeOptionsStyle>
            <TimeTitleStyle>
              <FormattedMessage id={"Label.TimePicker"} />
            </TimeTitleStyle>
            <TimeDropdownContainer ref={ref}>
              <TimeSelectedItemStyle
                onClick={() =>
                  setIsOpenTimeSelection((prevIsOpen) => !prevIsOpen)
                }
                isOpen={isOpenTimeSelection}
                selectedItem={selectedTime}
              >
                <img alt="clock" src={clock_icon} />
                <div>{selectedTime || "--:--"}</div>
                <img alt="drop_down" src={arrow_down_icon} />
              </TimeSelectedItemStyle>
              <SelectDialog
                options={(selectedDay && selectedDay.timeList) || []}
                onSelect={selectTime}
                open={isOpenTimeSelection}
                onClose={() => setIsOpenTimeSelection(false)}
              />
            </TimeDropdownContainer>
          </TimeOptionsStyle>
        }
      </>
    )
  }

  const getMonthHeader = () => {
    if (datesTimes?.statusCode === 200) {
      return datesTimes.body.availableTime[0].monthName
    }
    return "undefined"
  }

  const getSelectedDate = (): any => {
    if (selectedDay !== undefined) {
      return (
        <>
          <FormattedMessage id={selectedDay.dayOfWeek} /> {", "}{" "}
          <FormattedMessage id={selectedDay.monthName} />{" "}
          {selectedDay.dateValue}
        </>
      )
    }
    return "---, ------- 00 "
  }

  const getSelectedDateText = (): any => {
    if (selectedDay !== undefined) {
      return `${selectedDay.dayOfWeek}  ${selectedDay.monthName} ${selectedDay.dateValue}`
    }
    return "---, ------- 00 "
  }

  const getSelectedTime = (): string => {
    return `${selectedTime !== undefined ? selectedTime : ""}`
  }

  const bookingNowFunc = async () => {
    try {
      const date =
        selectedDay !== undefined
          ? `${selectedDay.yearValue}-` +
            `${String(selectedDay.monthValue).padStart(2, "0")}-` +
            `${String(selectedDay.dateValue).padStart(2, "0")}`
          : ""
      const time = getSelectedTime()
      const isAdult = adultsSelected
      const bookingRespond = await bookNow(date, time, isAdult)

      if ("error" in bookingRespond) {
        setErrorMessage(bookingRespond.message)
      } else {
        const date = String(getSelectedDateText())
        const time = String(selectedTime)
        const msg = formatMessage(
          { id: "bookingSuccessfullyMessage" },
          { date: date, time: time }
        )
        setSelectedTime(null)
        setSelectedDay(undefined)
        setSuccessfullyMessage(msg)
      }
    } catch (err) {
    } finally {
    }
  }

  const cancelFunc = () => {
    setInProgress(false)
    bookNowEnable.current = true
    closeFunc()
  }

  const bookNowButton = async () => {
    if (bookNowEnable.current) {
      setErrorMessage(null)
      setSuccessfullyMessage(null)
      setInProgress(true)
    }
  }

  const EditBookingFunc = async (appointmentId: String) => {
    try {
      const date =
        selectedDay !== undefined
          ? `${selectedDay.yearValue}-` +
            `${String(selectedDay.monthValue).padStart(2, "0")}-` +
            `${String(selectedDay.dateValue).padStart(2, "0")}`
          : ""
      const time = getSelectedTime()
      const isAdult = adultsSelected

      const bookingRespond = await editBook(appointmentId, date, time, isAdult)
      if ("error" in bookingRespond) {
        setErrorMessage(bookingRespond.message)
      } else {
        const date = String(getSelectedDateText())
        const time = String(selectedTime)
        const msg = formatMessage(
          { id: "editingSuccessfullyMessage" },
          { date: date, time: time }
        )
        setSelectedTime(null)
        setSelectedDay(undefined)
        setSuccessfullyMessage(msg)
      }
    } catch (err) {
    } finally {
    }
  }

  return (
    <>
      {inProgress && (
        <ConfirmMessage
          headerText={
            isEdit
              ? formatMessage({ id: "header.confirm.Editing" })
              : formatMessage({ id: "header.confirm.booking" })
          }
          bodyText={`${getSelectedDateText()} | ${getSelectedTime()}`}
          isConfirmed={errorMessage !== null || successfullyMessage !== null}
          isPassed={successfullyMessage !== null}
          message={errorMessage || successfullyMessage}
          confirmFunc={isEdit ? () => EditBookingFunc(isEdit) : bookingNowFunc}
          cancelFunc={cancelFunc}
        />
      )}
      {isOpen && <ScrollWindowBackgroundStyle onClick={closeFunc} />}
      <ScrollWindowContainerStyle className={isOpen ? "moving" : ""}>
        <CloseScrollButtonStyle>
          <CloseButtonStyle
            src={CloseButton}
            onClick={closePanel}
            onDrag={closePanel}
          />
        </CloseScrollButtonStyle>

        <HeaderContainer>
          <MonthTitleStyle>
            <FormattedMessage id={getMonthHeader()} />
          </MonthTitleStyle>
          <DateSwitchContainer>
            {/* <DateSwitchButtonStyle src={left_arrow} /> */}
            {/* <DateSwitchButtonStyle src={right_arrow} /> */}
          </DateSwitchContainer>
        </HeaderContainer>
        <DateTimeOptionsContainerStyle>
          <DateContainerStyle>
            <SideTitleContainer>
              <VerticalLineStyle />
              <FormattedMessage id={"Label.Date"} />
            </SideTitleContainer>
            <TableStyle>
              <RowTableStyle>{getDates()}</RowTableStyle>
            </TableStyle>
          </DateContainerStyle>
          <TimeContainerStyle>{getTimes()}</TimeContainerStyle>
          <KidsOptionContainer>
            <SideTitleContainer>
              <VerticalLineStyle />
              <FormattedMessage id={"Label.Time"} />
            </SideTitleContainer>
            <KidsOptionStyle>
              <CheckboxLabelStyle>
                <HiddenCheckboxStyle
                  checked={adultsSelected}
                  onChange={() => {
                    setAdultsSelected(!adultsSelected)
                  }}
                />
                <StyledCheckbox />
              </CheckboxLabelStyle>
              <KidsOptionDescriptionStyle>
                <FormattedMessage id={"Label.Adult"} />
                (14 +).
              </KidsOptionDescriptionStyle>
            </KidsOptionStyle>
            <KidsOptionStyle>
              <CheckboxLabelStyle>
                <HiddenCheckboxStyle
                  checked={!adultsSelected}
                  onChange={() => {
                    setAdultsSelected(!adultsSelected)
                  }}
                />
                <StyledCheckbox />
              </CheckboxLabelStyle>
              <KidsOptionDescriptionStyle>
                <FormattedMessage id={"Label.Child"} /> (14 -).
              </KidsOptionDescriptionStyle>
            </KidsOptionStyle>
          </KidsOptionContainer>
        </DateTimeOptionsContainerStyle>
        <BookNowContainerStyle>
          <BookNowCardStyle>
            <SelectedTimeStyle>
              <SelectedTimeCellStyle>
                <CalenderIconImage />
                <SelectedDateCellValueStyle>
                  {getSelectedDate()}
                </SelectedDateCellValueStyle>
              </SelectedTimeCellStyle>
              <SelectedTimeCellStyle>
                <SelectedTimeCellValueStyle>
                  {selectedTime}
                </SelectedTimeCellValueStyle>
              </SelectedTimeCellStyle>
            </SelectedTimeStyle>
            <BookNowButtonContainer>
              <BookNowButtonStyle
                onClick={bookNowButton}
                value={
                  inProgress
                    ? formatMessage({ id: "booking" })
                    : formatMessage({ id: "book_now" })
                }
                type="submit"
                disabled={inProgress || !bookNowEnable.current}
              />
            </BookNowButtonContainer>
          </BookNowCardStyle>
        </BookNowContainerStyle>
      </ScrollWindowContainerStyle>
    </>
  )
}

export default BookNowPanel
