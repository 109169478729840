import { FBStyle, FooterContainerStyle, InstagramStyle, LocationStyle, PhoneNumberStyle, SocialMediaContainerStyle, SignatureStyle, FooterDetailsStyle, FooterDetailLineStyle, IconStyle, SocialInstaGramHandler } from "./Footer.Style";
import FBIcon from "../../assets/icons/TwitterWhite.svg"
import InstagramIcon from "../../assets/icons/FBWhite.svg"
import PhoneIcon from "../../assets/icons/iphone.svg"
import { FormattedMessage } from "react-intl";

const openMapsApp = () => {
    const mapsUrl = "https://waze.com/ul/hsvc1gvs3m"
    window.open(mapsUrl, "_blank")
  }

  const openInstagramApp = () => {
    // Construct the maps URL with the fixed location
    // const mapsUrl = `https://maps.google.com/maps?q=${fixedLocation}`;
    const Url = "https://instagram.com/yousef.darawshe"
    // Open the URL in a new tab/window
    window.open(Url, "_blank")
  }

  const openFacebookApp = () => {
    // Construct the maps URL with the fixed location
    // const mapsUrl = `https://maps.google.com/maps?q=${fixedLocation}`;
    const Url = "https://www.facebook.com/profile.php?id=100007386392512&mibextid=LQQJ4d"
    // Open the URL in a new tab/window
    window.open(Url, "_blank")
  }

  const callNumber = () => {
    // Construct the maps URL with the fixed location
    // const mapsUrl = `https://maps.google.com/maps?q=${fixedLocation}`;
    const Url = "tel:0544542733"
    // Open the URL in a new tab/window
    window.open(Url, "_blank")
  }

const Footer = () => {
    return (
        <>
            <FooterContainerStyle>
                <FooterDetailsStyle>
                    <FooterDetailLineStyle>

                        <LocationStyle onClick={openMapsApp}>
                            <FormattedMessage id={'Data.Location'}/>
                        </LocationStyle>
                    </FooterDetailLineStyle>
                    <FooterDetailLineStyle>
                        <IconStyle src={PhoneIcon} />
                        <PhoneNumberStyle onClick={callNumber}>
                            054-454-2733
                        </PhoneNumberStyle>
                    </FooterDetailLineStyle>
                </FooterDetailsStyle>
                <SocialMediaContainerStyle>
                <SocialInstaGramHandler onClick={openInstagramApp}/>
                    {/* <FBStyle src={FBIcon} /> */}
                    <InstagramStyle src={InstagramIcon} onClick={openFacebookApp}/>
                </SocialMediaContainerStyle>
                {/* <SignatureStyle>
                </SignatureStyle> */}
            </FooterContainerStyle>
        </>
    )
}

export default Footer;