import styled from "styled-components";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

export const TableContainerStyle = styled.div`
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    height: calc(100% - 50px );
    overflow-x: hidden;
`

export const RowStyle = styled.div`
    direction: ltr;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto !important;
    
    background: #FF4F2D;
    /* padding: 5px; */
    border-radius: 8px;
    margin: 10px;
`

export const TimeStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    font-family: 'Jost',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    border-left: 5px solid #222532;
    border-top: 5px solid #222532;
    border-radius: 15px 0 0 0;
    color: #222532;
    padding: 0.9rem;
    translate: 15px 10px;
`

export const HrStyle = styled.hr`
    border: 3px solid #222532;
    display: flex;
    bottom: 0%;
    ;
`

export const NameStyle = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    font-family: 'Jost',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    color: #222532;
    margin: 5px;
`

export const ButtonsContainerStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    gap: 12px;
    padding: 0.5rem;
`

export const ButtonIconStyle = styled.img`
    width: 39px;
    height: 39px;
    cursor: pointer;
    
    &:hover {
        filter: invert(17%) sepia(43%) saturate(5894%) hue-rotate(346deg) brightness(89%) contrast(91%);
    }
`

export const ButtonStyle = styled.a`
    /* width: 39px;
    height: 39px; */

    &:hover ${ButtonIconStyle} {
        fill: #b0943e;
    }
`

export const TopClientTabDiv = styled.div`
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    /* padding:2px 10px; */
    /* width: calc(100% -20px); */
`;

export const BottomClientTabDiv = styled.div`
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    width: calc(100% - 0.7rem);
`;

export const AdultChildMainDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #222532;
    padding:2px;
    font-family: 'Jost',sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const AdultChildImage = styled.img`

`;

export const StyledWhatsappIcon = styled(WhatsAppIcon)`
  color:  #77F67C ; // Replace with your desired color: ;
  font-size: 3rem !important;
  &:hover {
    color: white; // Color on hover, if needed
  }
`;

export const StyledNotificationIcon = styled(NotificationsActiveIcon)`
  color: #FFCF2C; // Replace with your desired color
  width: 40px;
  height: 40px;
  font-size: 3rem !important;
  &:hover {
      color: white; // Color on hover, if needed
    }
    `;
    
    export const StyledProfileIcon = styled(AccountBoxIcon)`
      color: #13639D; // Replace with your desired color: ;
      width: 40px;
      height: 40px;
      font-size: 3rem !important;
      &:hover {
        color: white; // Color on hover, if needed
      }
    `;

export const StyledDeleteIcon = styled(DeleteIcon)`
    color: #E21B1B; // Replace with your desired color
    width: 40px;
    height: 40px;
    font-size: 3rem !important;
    &:hover {
      color: white; // Color on hover, if needed
    }
`;