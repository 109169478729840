import styled from "styled-components";

interface ButtonProps {
    clicked: boolean;
}

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-family: 'Jost',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 39px;
    color: #222532;
    gap:5px;
    
`

export const TodayButtonStyle = styled.div<ButtonProps>`
    display: flex;
    width: calc(5vh);
    height: calc(5vh);
    justify-content: center;
    align-items: center;
    border-radius: 90px;
    max-width: 220px;
    min-width: 100px;
    font-weight:400;
    font-size: 28px;
    background: ${(props) => (props.clicked ? '#FF2900' : '#FF4F2D')};
    &:hover {
        background: ${(props) => (props.clicked ? '#FF2900' : '#FF4F2D')};
        cursor: pointer;
    }
`

export const ButtonStyle = styled.div<ButtonProps>`
    display: flex;
    width: calc(5vh);
    height: calc(5vh);
    justify-content: center;
    align-items: center;
    font-size: small;
    border-radius: 190px;
    font-weight:400;   
    font-size: 20px;
    background: ${(props) => (props.clicked ? '#FF2900' : '#FF4F2D')};
    &:hover {
        background: ${(props) => (props.clicked ? '#FF2900' : '#b0943e')};
        cursor: pointer;
    }
`