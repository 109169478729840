import styled, { css, keyframes } from "styled-components";

export const ButtonContainer = styled.div`
    position: absolute;
    /* width: 100%; */
    z-index: 2;
    bottom: 0;
    right: 0;
    margin-bottom: calc(5em + 4px);
    margin-right: calc(1em + 4px);
`

export const BookingButtonContainer = styled.div`
    /* position: absolute; */
    display: flex;
    justify-content: center;
    right: 20px;
    bottom: 20px;
    border-radius: 90px;
    background-color: #FF4F2D;
    width: 60px;
    height: 60px;
`

export const BookingButtonStyle = styled.img`
    width: 50px;
`

const popUpIcon = keyframes`
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
`;

const appearIcon = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

interface ButtonOptionProps {
    index: number;
}

export const ButtonOptionContainer = styled.div<ButtonOptionProps>`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    /* right: 23px; */
    bottom: ${props => props.index!==0?90 + props.index * 60:145}px;
    animation: ${props => props.index !== 0 ? css`${popUpIcon} 0.5s ease forwards` : css`${appearIcon} 0.5s ease forwards`};
    animation-delay: ${props => props.index * 0.1}s;
    transform: translateY(100%);
    background-color: #FF4F2D;
    width: 54px;
    height: 54px;
    border-radius: 90px;
    opacity: 0;
`;

export const ButtonOptionStyle = styled.img`
    width: 35px;
    height: 35px;
`

export const OptionLabelStyle = styled.span`
    position: absolute;
    right: 100%;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    font-family: 'Jost',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    color: #222532;

    ${ButtonOptionContainer}:hover & {
        opacity: 1;
        transform: translateX(-5px);
    }
`;

export const BackgroundContainer = styled.div`
    position: absolute;
    top: 0;
    width: calc(100% + 70em);
    left: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    z-index: 1;
`