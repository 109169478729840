import { useEffect, useRef, useState } from "react";
import { BoxBodyContainer, BoxContainer, BoxTitleStyle, DayOptionContainer, DeleteButtonStyle, FormContainer, HeaderStyle, OptionsListContainer, ShiftContainer, ShiftsContainer, ShiftsIconStyle, ShiftsListContainer, SubmitButtonStyle, SubmitContainer, TDDeleteStyle, TDStyle, THDeleteStyle, THStyle, TRStyle, TableBodyStyle, TableHeaderStyle, TableStyle } from "./Style";
import { FormattedMessage } from "react-intl";
import { reset } from "../../store/messagesSlice";
import { useDispatch } from "react-redux";
import ShiftType from "../../types/rest/ShiftType";
import { getSchedule, getShifts, updateShiftsFromSchedule } from "../../services/BackendServices";
import ShiftsList from "../shiftsList/ShiftsList";
import CancelIcon from "../assets/icons/XIcon.svg";
import IProps from "./IProps";
import WorkingDayType from "../../types/rest/WorkingDayType";

const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

const WorkingScheduleBox: React.FC<IProps> = ({onClose}) => {
    const dispatch = useDispatch();

    const [selectedDay, setSelectedDay] = useState("sun");

    const [shifts, setShifts] = useState<ShiftType[]>([]);
    const [schedule, setSchedule] = useState<WorkingDayType[]>([]);
    const [selectedShifts, setSelectedShifts] = useState<ShiftType[]>([]);

    const [isScrolling, setScrolling] = useState(false);
    const [startScroll, setStartScroll] = useState(0);
    const divRef = useRef<HTMLDivElement>(null);

    const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setScrolling(true);
        setStartScroll(e.clientY);
    };

    const onMouseUp = () => {
        setScrolling(false);
    };

    const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!isScrolling || !divRef.current) return;

        divRef.current.scrollTop += startScroll - e.clientY;
        setStartScroll(e.clientY);
    };

    useEffect(() => {
        dispatch(reset());
        fetchData();
    }, [])

    useEffect(() => {
        const selectedShifts = schedule.find(day=>day.dayOfWeek===selectedDay);
        if(selectedShifts) {
            const shifts = selectedShifts.shifts;
            setSelectedShifts(shifts);
        }
    }, [selectedDay, schedule])

    const fetchData = async () => {
        const shifts = await getShifts();
        if (Array.isArray(shifts)) {
            setShifts(shifts);
        }
        else {
            setShifts([]);
        }

        const schedule = await getSchedule();
        if (Array.isArray(schedule)) {
            setSchedule(schedule);
        }
        else {
            setSchedule([]);
        }
    }

    const addShift = (shiftName: string) => {
        const newShift = shifts.find(shift => shift.shiftName === shiftName);
        if (newShift) {
            setSelectedShifts([...selectedShifts, newShift]);
        }
        else {
            console.log("Shift Not Found.")
        }
    }

    const deleteShiftFromDay = (shiftId: string) => {
        const updatedShifts = selectedShifts.filter(shift => shift.id !== shiftId);
        setSelectedShifts(updatedShifts);
    }

    const getShiftsRows = () => {
        return (
            <TableBodyStyle
                ref={divRef}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onMouseLeave={onMouseUp}
                onMouseMove={onMouseMove}
            >
                {selectedShifts.map(shift => {
                    return <TRStyle>
                        <TDStyle>{shift.shiftName}</TDStyle>
                        <TDStyle>{shift.fromHour}</TDStyle>
                        <TDStyle>{shift.toHour}</TDStyle>
                        <TDStyle>{`${shift.mpc}`}</TDStyle>
                        <TDDeleteStyle>
                            <DeleteButtonStyle src={CancelIcon} onClick={() => deleteShiftFromDay(shift.id)} />
                        </TDDeleteStyle>
                    </TRStyle>
                })}
            </TableBodyStyle>
        )
    }

    const getAvailableShifts = () => {
        const selectedShiftsName = selectedShifts.flatMap(shift => shift.shiftName);
        const shiftsToDisplay = shifts.flatMap(shift => shift.shiftName)
            .filter((shiftName: string) => {
                return !selectedShiftsName.includes(shiftName);
            }
            );
        return shiftsToDisplay;
    }

    const submitForm = async () => {
        const day = selectedDay;
        const selectedShiftIds:String[] = selectedShifts.flatMap(shift=>shift.id);
        const restResp = await updateShiftsFromSchedule(day,selectedShiftIds);
        if(restResp.statusCode === 200) {
            onClose(true);
        }
    }

    return (
        <>
            <BoxContainer>
                <HeaderStyle>
                    {
                        days.map(day => {
                            return <DayOptionContainer
                                className={(selectedDay === day && "selected") || "normal"}
                                onClick={() => setSelectedDay(day)}>
                                <FormattedMessage id={day} />
                            </DayOptionContainer>
                        })
                    }
                </HeaderStyle>
                <BoxBodyContainer>
                    <BoxTitleStyle>Working Schedule</BoxTitleStyle>
                    <FormContainer>
                        <ShiftsContainer>
                            <ShiftsIconStyle>Shifts:</ShiftsIconStyle>
                            <ShiftsListContainer>
                                <ShiftsList
                                    shifts={getAvailableShifts()}
                                    onClick={addShift} />
                            </ShiftsListContainer>
                        </ShiftsContainer>
                        <TableStyle>
                            <TableHeaderStyle>
                                <TRStyle>
                                    <THStyle>
                                        <FormattedMessage id="name" />
                                    </THStyle>
                                    <THStyle>
                                        <FormattedMessage id="from" />
                                    </THStyle>
                                    <THStyle>
                                        <FormattedMessage id="to" />
                                    </THStyle>
                                    <THStyle>
                                        <FormattedMessage id="mpc" />
                                    </THStyle>
                                    
                                </TRStyle>
                            </TableHeaderStyle>
                            {getShiftsRows()}
                        </TableStyle>
                    </FormContainer>
                    <SubmitContainer>
                        <SubmitButtonStyle onClick={submitForm}>
                            Save
                        </SubmitButtonStyle>
                    </SubmitContainer>
                </BoxBodyContainer>
            </BoxContainer>
        </>
    )
}

export default WorkingScheduleBox;