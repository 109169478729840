import barberLogo from '../assets/images/salon_yousef_logo.svg'
import IButtonProps from "./IButtonProps";
import { ImageContainer, MovingImage } from "./Styles";

const MovingLogo: React.FC<IButtonProps> = ({isTop}) => {

  return (
    <ImageContainer>
        <MovingImage alt="barber_Shop" src={barberLogo} className={`${isTop ? 'moving' : ''}`} />
    </ImageContainer>
  );
}

export default MovingLogo;